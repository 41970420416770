import fetch from "../services/fetch";
import store from "../store";

export default {
  getAll,
  getExpenseReportStats,
  getExpenseReportDataYears,
  exportReports,
  updateReport,
  history,
  accept,
  refuse,
  getExcelData,
  payReport,
  unTrash,
};

function getAll(params) {
  return fetch("api/expense-reports", {
    method: "get",
    headers: { Authorization: store.state.token },
    params: params,
  });
}

async function getExcelData(reportsId) {
  try {
    let result = await fetch("api/expense-reports/get-reports-data", {
      method: "POST",
      headers: {
        Authorization: store.state.token,
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        ids: reportsId,
      }),
    });

    result = result.map(item => {
      {
        return {
          Date: item.date,
          JAL: item.type,
          Piece: item.id,
          Libellé: item.fullName,
          D: item.debit,
          C: item.credit,
          Compte: item.account,
          E: item.currency,
        };
      }
    });

    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
}

function getExpenseReportStats(params) {
  return fetch("api/expense-reports/stats", {
    method: "get",
    headers: { Authorization: store.state.token },
    params: params,
  });
}

function getExpenseReportDataYears(params) {
  return fetch("api/expense-reports/get-reports-data-years", {
    method: "get",
    headers: { Authorization: store.state.token },
    params: params,
  });
}

function accept(reportsId) {
  return fetch("api/expense-reports/update-status", {
    method: "POST",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ids: reportsId,
      status: "accepted",
    }),
  });
}

function refuse(reportsId) {
  return fetch("api/expense-reports/update-status", {
    method: "POST",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ids: reportsId,
      status: "refused",
    }),
  });
}

function unTrash(reportsId) {
  return fetch("api/expense-reports/update-status", {
    method: "POST",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ids: reportsId,
      status: "waiting",
    }),
  });
}

function history() {
  return fetch("api/expense-reports", {
    method: "get",
    headers: { Authorization: store.state.token },
    params: { type: "history" },
  });
}

function exportReports(reportsId) {
  return fetch("api/expense-reports/export", {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ids: reportsId,
    }),
  });
}

function updateReport(payload) {
  return fetch("api/expense-reports", {
    method: "put",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
}

function payReport(payload) {
  return fetch("api/expense-reports/pay", {
    method: "put",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
}
