<template>
  <div v-if="!isLoading" class="expense-report-pay">
    <p v-if="type === 'company'" class="expense-report-pay-text">
      {{
        $t("expense-notes.pay-table.text", {
          month: this.$$filters.formatFullMonth(new Date().getMonth()),
          year: new Date().getFullYear(),
        })
      }}
    </p>
    <sortable-list
      :items="modifiedReports"
      :parameters="reportParameters"
      default-sort="authorName"
      @item-click="onItemClick"
    />
    <p
      class="expense-report-pay-total"
      v-html="$t('expense-notes.pay.total-text', { cash: totalTVC })"
    />
    <div class="expense-report-pay-input">
      <div class="expense-report-pay-field">
        <md-datepicker v-model="payData.paidDate">
          <label>{{ $t("expense-notes.pay.paid-date") }}</label>
        </md-datepicker>
      </div>
      <div class="expense-report-pay-field">
        <md-field>
          <label for="paymentMethod">{{
              $t("expense-notes.pay.payment-method")
            }}</label>

          <md-select v-model="payData.paymentMethod" name="paymentMethod">
            <md-option value="Chèque">Chèque</md-option>
            <md-option value="Virement bancaire">Virement bancaire</md-option>
          </md-select>
        </md-field>
      </div>
      <div class="expense-report-pay-button">
        <md-button v-if="type === 'company'" @click="onBack">
          {{ $t("expense-notes.pay.cancel") }}
        </md-button>
        <md-button @click="onPay">
          {{ $t("expense-notes.pay.valid") }}
        </md-button>
      </div>
    </div>
  </div>
  <LoadingCard v-else />
</template>
<script>
import expenseReports from "@/model/expense-reports";
import LoadingCard from "../../components/LoadingCard.vue";

export default {
  name: "ExpenseReportPay",
  components: { LoadingCard },
  props: { reports: Array, type: String },
  data() {
    return {
      modifiedReports: [],
      totalTVC: 0,
      payData: {
        paidDate: new Date(),
        paymentMethod: "bank",
        ids: [],
      },
      isLoading: false,
    };
  },

  computed: {
    reportParameters() {
      if (this.isEmployee) {
        return [
          {
            name: this.$t("expense-notes.employees.table-date"),
            key: "date",
            format: this.$$filters.formatDate,
          },
          {
            name: this.$t("expense-notes.employees.table-title"),
            key: "title",
          },
          {
            name: this.$t("expense-notes.employees.table-category"),
            key: "accountingAccount.name",
          },
          {
            name: this.$t("expense-notes.employees.table-amount"),
            key: "amount",
            format: this.$$filters.formatNumber,
          },
          {
            name: this.$t("expense-notes.employees.table-tva"),
            key: "tva",
            format: this.$$filters.formatNumber,
          },
          {
            name: this.$t("expense-notes.employees.table-total"),
            key: "total",
            format: this.$$filters.formatNumber,
          },
          {
            name: "",
            isIcon: true,
            iconFormat: item => {
              return item.fileId ? "attach_file" : "";
            },
            iconClass: () => "",
            clickable: true,
          },
        ];
      } else
        return [
          {
            name: this.$t("expense-notes.pay-table.name"),
            key: "authorName",
          },
          {
            name: this.$t("expense-notes.pay-table.amount"),
            key: "amount",
          },
          {
            name: this.$t("expense-notes.pay-table.tva"),
            key: "tva",
            format: this.$$filters.formatNumber,
          },
          {
            name: this.$t("expense-notes.pay-table.total"),
            key: "totalMonthTVC",
            format: this.$$filters.formatNumber,
          },
        ];
    },

    isEmployee() {
      return this.type === "employee";
    },
  },
  mounted() {
    this.modifiedReports = this.reports;
    if (this.isEmployee) {
      this.modifiedReports.forEach(report => {
        report.total = report.tva + report.amount;
        this.totalTVC += report.tva + report.amount;
        this.payData.ids.push(report.id);
      });
      if (this.reports.length === 0) {
        this.totalTVC = 0;
        this.payData.ids = [];
      }
    } else {
      this.modifiedReports.forEach(userReport => {
        this.totalTVC += userReport.totalMonthTVC;
        userReport.reports.forEach(report => {
          this.payData.ids.push(report.id);
        });
      });
    }
  },

  methods: {
    onItemClick({ item }) {
      this.$router.push("/dashboard/cloud/" + item.fileId);
    },

    async onPay() {
      this.isLoading = true;

      try {
        await expenseReports.payReport(this.payData);
        this.$toasted.global.AppSucces({
          message: this.$t("expense-notes.employees.pay-success", {
            month: this.$$filters.formatFullMonth(new Date().getMonth()),
          }),
        });
        this.$emit("back");
      } catch (error) {
        console.log(error);
        this.$toasted.global.AppError({
          message: this.$t("expense-notes.employees.action-fail"),
        });
      }
      this.isLoading = false;
    },

    onBack() {
      this.$emit("back");
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/_variable.scss";

#app {
  .expense-report-pay {
    &-text {
      width: 500px;
    }

    &-total {
      font-size: toRem(12);
      text-align: right;
      @include for-lg{
        font-size: toRem(16);
      }

      b {
        font-size: toRem(14);
        @include for-lg{
          font-size: toRem(20);
        }
      }
    }

    &-input {
      max-width: 450px;
      display: flex;
      flex-direction: column;

      .md-button {
        width: 120px;
        height: 40px;
        font-family: var(--font-bold);
        font-size: 16px;
        color: #fff;
        background-color: var(--bg-primary);
        border-radius: 4px;
      }
    }

    &-field {
      max-height: 75px;
      background-color: white;
      padding: 0 20px;
      width: 100%;
      box-sizing: border-box;
      margin-bottom: 15px;
      border-radius: 4px;

      .md-field {
        padding: toRem(20) 0 0 toRem(12);

        input {
          font-size: toRem(12);
          @include for-lg {
            font-size: toRem(16);
          }
        }

        .md-icon-image {
          display: none;
        }

        @include for-lg {
          padding: toRem(20) 0 0 toRem(20);
        }

        &:after {
          display: none;
        }

        height: 65px;
      }

      .md-datepicker {
        background-color: white;

        .md-input {
          margin-left: 0;
          margin-top: toRem(5);
        }

        .md-button {
          display: none;
        }
      }

      .md-select {
        overflow: hidden;
        margin-top: 5px;
        background-color: white !important;

        .md-input {
          padding-top: 0;
        }
      }

      .md-datepicker,
      .md-field {
        label {
          left: 12px;
          top: 24px;
          @include for-lg {
            left: 20px;
          }
        }
      }

      .md-has-value,
      .md-focused {
        label {
          font-size: 12px;
          top: 10px !important;
        }
      }
    }

    &-button {
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>
