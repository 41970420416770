<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51 51"><path fill="#fff" stroke="#fff" stroke-width=".5" d="M25.501 12a14 14 0 1014 14 14.016 14.016 0 00-14-14zm0 26.972a12.972 12.972 0 1112.973-12.973 12.987 12.987 0 01-12.973 12.973z"/><path fill="#fff" d="M25.038 30.061a.784.784 0 01-.17-.535 5.606 5.606 0 01.547-2.577 13.846 13.846 0 011.568-2.334 13.562 13.562 0 001.228-1.775 3.322 3.322 0 00.4-1.629 1.978 1.978 0 00-.754-1.641 3.207 3.207 0 00-2.042-.6 4.77 4.77 0 00-2.018.426 11.889 11.889 0 00-2.091 1.3 1.3 1.3 0 01-.681.292.558.558 0 01-.474-.255 1 1 0 01-.182-.6 1.132 1.132 0 01.389-.851 7.2 7.2 0 012.371-1.471 7.594 7.594 0 012.784-.547 5.29 5.29 0 013.416 1.046 3.355 3.355 0 011.3 2.748 4.332 4.332 0 01-.547 2.225 14.629 14.629 0 01-1.666 2.2 17 17 0 00-1.617 2.1 4.823 4.823 0 00-.693 2.1.59.59 0 01-.195.413.648.648 0 01-.438.146.554.554 0 01-.435-.181zm-.5 4.279a1.34 1.34 0 01-.377-.972 1.384 1.384 0 01.377-.985 1.351 1.351 0 011.921 0 1.386 1.386 0 01.377.985 1.342 1.342 0 01-.377.972 1.38 1.38 0 01-1.921 0z"/></svg>
</template>

<script>
  export default {
    name : 'MeepIconCircleQuestion'
  };
</script>
